<app-expandable [expandable]="false" [title]="'GM Forms'">

  <div class="container">

    <br>
    You can download forms electronically that you previously had to request by phone or mail.&nbsp; Select a form to download below.
    <br><br>
    <b>If your state is not listed below, your state does not have a required document.</b>
    <br><br>
    If you require further assistance, please contact the Help Desk at 1-800-584-2000.
    <br><br>
    Please close the tab after you are finished.

    <div class="files">
      <dl>
        <dt><b>Separation State Unemployment Documents</b></dt><br>
        <dd><a href="assets/Forms/stateUC/Alabama Notice of Availability of Unemployment Compensation.pdf" target="_blank">Alabama</a></dd>
        <dd><a href="assets/Forms/stateUC/ARIZONA Notice to Employee of Potential Unemployment Compensation UIB-1241A.pdf" target="_blank">Arizona</a></dd>
        <dd><a href="assets/Forms/stateUC_2/Arkansas%20Appendix-A-New-Rule-5-Updated-8.2022.pdf" target="_blank">Arkansas</a></dd>
        <dd><a href="assets/Forms/stateUC/California Notice to Employee of Potential Unemployment Compensation.pdf" target="_blank">California</a></dd>
        <dd><a href="assets/Forms/stateUC_3/Colorado Employer Separation Form 22-234.pdf" target="_blank">Colorado</a></dd>
        <dd><a href="assets/Forms/stateUC_3/Connecticut%20UC-62.pdf" target="_blank">Connecticut</a></dd>
        <dd><a href="assets/Forms/stateUC_3/Georgia%20Separation%20form%20to%20Employee.pdf" target="_blank">Georgia</a></dd>
        <dd><a href="assets/Forms/stateUC/Illinois Pamphlet for Separated Employees.pdf" target="_blank">Illinois</a></dd>
        <dd><a href="assets/Forms/stateUC/Louisiana UI_AvailabilityNoticeToSeparatingEmployees.pdf" target="_blank">Louisiana</a></dd>
        <dd><a href="assets/Forms/stateUC/Maryland uiavailnotice.pdf" target="_blank">Maryland</a></dd>
        <dd><a href="assets/Forms/stateUC/Massachussets Pamphlet for Separated Employees.pdf" target="_blank">Massachusetts</a></dd>
        <dd><a href="assets/Forms/stateUC_3/Michigan UIA 1711.pdf" target="_blank">Michigan</a></dd>
        <dd><a href="assets/Forms/stateUC_2/Missouri%20MODES-B-2-AI.pdf" target="_blank">Missouri</a></dd>
        <dd><a href="assets/Forms/stateUC_2/Nevada%20Employer_notification_of_UI_to_employees_4-24-20.pdf" target="_blank">Nevada</a></dd>
        <dd><a href="assets/Forms/stateUC_3/New%20Jersey%20Separation%20Form%20for%20Employees%20needs%20update.pdf" target="_blank">New Jersey</a></dd>
        <dd><a href="assets/Forms/stateUC_3/New%20York%20ia12.3_0.pdf" target="_blank">New York</a></dd>
        <dd><a href="assets/Forms/stateUC_2/Oklahoma%20Unemployment%20Poster.pdf" target="_blank">Oklahoma</a></dd>
        <dd><a href="assets/Forms/stateUC/Pennsylvania UC-1609.pdf" target="_blank">Pennsylvania</a></dd>
        <dd><a href="assets/Forms/stateUC_2/Rhode%20Island%20notice.pdf" target="_blank">Rhode Island</a></dd>
        <dd><a href="assets/Forms/stateUC/South Carolina families-first-required-notice_uie646d9b195084b58bcfaf2b06f0ca5ff.pdf" target="_blank">South Carolina</a></dd>
        <dd><a href="assets/Forms/stateUC_2/South%20Dakota%20posting_notice_to_employees_electronic.pdf" target="_blank">South Dakota</a></dd>
        <dd><a href="assets/Forms/stateUC_3/Tennessee LB-0489.pdf" target="_blank">Tennessee</a></dd>
        <dd><a href="assets/Forms/stateUC_2/Virginia%20B29%20Notice%20to%20Workers_Effective%203_24.pdf" target="_blank">Virginia</a></dd>
        <dd><a href="assets/Forms/stateUC_2/Washington%20ESD-unemployment-benefits-poster.pdf" target="_blank">Washington</a></dd>
        <dd><a href="assets/Forms/stateUC_2/Wisconsin%20Unemployment%20Poster.pdf" target="_blank">Wisconsin</a></dd>
      </dl>
    </div>

    <br>
    <br>
    <!--    <p alight="left">-->
    <!--      <a href="https://get.adobe.com/reader/" target="_blank">-->
    <!--        <img src="assets/images/adobe.gif" width="88" height="31" border="0"></a>-->
    <!--      <small>The above forms are viewable and printable from Adobe Acrobat Reader. Follow this link to-->
    <!--        download a free version of Acrobat Reader if you do not already have a version on your computer. </small>-->
    <!--    </p>-->

  </div>
</app-expandable>
