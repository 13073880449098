import { Component } from '@angular/core';

@Component({
  selector: 'app-salary-forms-2-25',
  templateUrl: './salary-forms-2-25.component.html',
  styleUrl: './salary-forms-2-25.component.css'
})
export class SalaryForms225Component {
  constructor(
  ) {
  }
}
