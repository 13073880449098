import { masks } from '../../masks';

let submit = {

  wbaDollars: {
    minLen: null,
    inputMaxLen: 4,
    maxLen: 4,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  wbaCents: {
    minLen: null,
    inputMaxLen: 2,
    maxLen: 2,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  mbaDollars: {
    minLen: null,
    inputMaxLen: 5,
    maxLen: 5,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  mbaCents: {
    minLen: null,
    inputMaxLen: 2,
    maxLen: 2,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  weeksDollars: {
    minLen: null,
    inputMaxLen: 2,
    maxLen: 2,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
  weeksCents: {
    minLen: null,
    inputMaxLen: 1,
    maxLen: 1,
    mask: null,
    maskMessage: null,
    maskToolTip: null,
    required: false
  },
};


export {submit};
