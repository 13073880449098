
<!--<app-expandable  [expandable]="false" title='Frequently Asked Questions'>-->
<!--  <div *ngFor="let item of data; let i = index;">-->
<!--    <button class="accordion" (click)="toggleAccordian($event, i)" >{{item.sections}}</button>-->
<!--    <div class="panel" >-->
<!--      <ul *ngFor="let child of item.responses; let x = index;">-->
<!--        <p><b>{{item.responses[x].question}}</b></p>-->
<!--        <p [innerHTML]="item.responses[x].response">-->
<!--        {{item.responses[x].response}}-->
<!--        </p>-->
<!--      </ul>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div style="padding-bottom: 30px">-->
<!--  <p align="center" style="font-size: 16px"> <strong>-->
<!--&lt;!&ndash;    [routerLink]="/mylayoffbenef" routerLinkActive="active" routerLinkActiveOptions="{exact:true}"&ndash;&gt;-->
<!--    <a routerLink="/mylayoffbenef" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">Contact Us with Your Questions and Comments</a>-->
<!--  </strong></p>-->
<!--  </div>-->
<!--</app-expandable>-->


<app-expandable  [expandable]="false" title='Frequently Asked Questions'>
  <div >
    <button class="accordion" (click)="toggleAccordion($event)">General Information</button>
    <div class="panel" >
      <ul >
        <p><b>What is the GM Layoffs and Unemployment Center?</b></p>
        <p>The GM Layoffs and Unemployment Center is the administrator for the GM
          layoff benefit plan (SUB) and unemployment compensation activities.</p>
      </ul>
      <ul >
        <p><b>What is the GM Layoffs and Unemployment Center's address?</b></p>
        <p> The mailing address for the GM Layoffs and Unemployment Center is:
        <p align="center">GM Layoffs and Unemployment Center<br> P.O. Box 5078<br> Southfield, MI 48086-5078</p>
      </ul>
      <ul >
        <p><b>How do I reach the GM Layoffs and Unemployment Center by phone or fax?</b></p>
        <p> The phone and fax numbers for the GM Layoffs and Unemployment Center are:
          <p align="center"> Phone: 1-800-584-2000<br>Fax: 1-248-365-9809 </p>
      </ul>
      <ul >
        <p><b>How can I get information about my other GM benefits?</b></p>
        <p>Visit gmbenefits.com or call 1-800-584-2000.</p>
      </ul>
    </div>
    <button class="accordion" (click)="toggleAccordion($event)">Applying for Layoff Benefits</button>
    <div class="panel" >
      <ul >
        <p><b>How do I apply for a SUBenefit?</b></p>
        <p>To apply for benefits, eligible employees may call our
          <a routerLink="/vru" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
            Interactive Voice Response (IVR)</a> at 1-800-584-2000 or click on
          <a routerLink="/mylayoffbenef" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
            My Layoff Benefits</a>. If you are from a location covered by the AutoSUB Program,
          you do not need to apply for a SUB benefit using the IVR or the Layoff Benefits web site.
          Your certification for Unemployment Compensation (UC) benefits with your state will initiate
          the SUB benefit payment automatically.  Please refer to “The AutoSUB Program” section for
          additional information.  In certain situations, you may need to complete a  SUB application,
          titled "Multiple-Week Application for SUB benefits" in the
          <a routerLink="/gmforms" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
            Forms</a> section.</p>
      </ul>
      <ul >
        <p><b>How do I apply for TSP?</b></p>
        <p>To apply for benefits, eligible employees may call our
          <a routerLink="/vru" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
            Interactive Voice Response (IVR)</a> at 1-800-584-2000 or click on
          <a routerLink="/mylayoffbenef" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
            My Layoff Benefits</a>. Please refer to “The AutoSUB Program” section if you are from a
          location covered by the AutoSUB Program.</p>
      </ul>
      <ul >
        <p><b>What if I need assistance with the IVR or web site application process?</b></p>
        <p> GM Layoffs and Unemployment Center Analysts are
          <a routerLink = "/hours" routerLinkActive="active" routerLinkActiveOptions="{exact:true}" >available
            during normal business hours</a> to handle any problems and to explain
          the process to you. However, you are required to do the
          actual input of information into the IVR or on this web site through the
          <a routerLink="/mylayoffbenef" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
            My Layoff Benefits</a> section. The GM Layoffs and Unemployment Center Analyst provides verbal assistance.</p>
      </ul>
      <ul >
        <p><b>How soon after my layoff begins can I apply for a layoff benefit?</b></p>
        <p>Your home plant must enter the proper layoff status into Workday
          and you must have met all applicable UC reporting and application
          requirements before you may apply for a layoff benefit.</p>
        <p>
          You must wait to file an
          application until you have received your monetary determination
          from the state UC agency. You must also wait until you
          receive your weekly UC payment before you apply for your layoff
          benefit for the same week. If you have exhausted UC
          eligibility, you must wait until the Monday following each full
          week of layoff before applying for a layoff benefit related to
          the completed layoff week.
        </p>
      </ul>
    </div>
    <button class="accordion" (click)="toggleAccordion($event)">The AutoSUB Program</button>
    <div class="panel" >
      <ul >
        <p><b> What is AutoSUB and how do I know if my plant is covered under the Program?</b></p>
        <p>AutoSUB is an automated procedure, in which the GM Layoffs and Unemployment Center
          receives state UC benefit information electronically from the
          State. The UC information is used to calculate the payment of
          a regular SUBenefit for each full week of layoff. When the
          AutoSUB procedures apply, your application for a state UC benefit
          will constitute submitting an application for regular SUBenefits,
          you should not need to use the IVR or Layoff Benefits website to apply.</p>
        <p>
          AutoSUB is used for GM employees in Michigan and New York, as well as those
          working at GM assembly plants in Arlington and Wentzville.
        </p>
      </ul>
    </div>
    <button class="accordion" (click)="toggleAccordion($event)">Layoff Benefits Processing</button>
    <div class="panel" >
      <ul >
        <p><b>How often does the GM Layoffs and Unemployment Center process layoff benefits?</b></p>
        <p>Layoff benefits are processed weekly, generally on Thursday nights. Gross
          benefit amounts are transmitted to Payroll weekly, generally on
          Fridays. Payroll generates SUB or TSP payments on Friday of
          the following week.</p>
        <p>You can view your pending and transmitted SUB benefits after logging
          into the <a routerLink="/mylayoffbenef" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
            My Layoff Benefits</a> option and selecting "View Your Processed
          and Pending History", which will also display when the funds will be
          made available.</p>
      </ul>
      <ul >
        <p><b>I have not received my layoff benefits.</b></p>
        <p> GM Payroll issues SUB or TSP payments. Contact the Hourly Payroll Team at
          1-866-245-5957 regarding missing payments.
        </p>
      </ul>
      <ul >
        <p><b>How is my SUBenefit calculated?</b></p>
        <p> The purpose of
          the SUB Plan is to supplement state UC benefits while you are on a
          layoff. If you have at least 90 days of seniority (as defined
          in the SUB Plan) as of your last day worked, your SUBenefit will be equal
          to 74% of your base hourly rate as of your last day worked.
          Your SUBenefit is also offset by state Unemployment Compensation
          (UC) benefits and other compensation received for the specified
          week. </p>
        <p> To view a sample calculation, see the attached example: <a href="assets/Forms/Example SUB Calcs.pdf" target="blank"> Example SUB Calculation</a></p>
      </ul>
      <ul >
        <p><b>If I have wages from another job for the same week that I am applying for a SUBenefit, do
          I have to declare these earnings?</b></p>
        <p>Yes. You
          must report any outside earnings. However, only 80% of these
          earnings will be deducted from your SUBenefit for the week.</p>
      </ul>
      <ul >
        <p><b>My state requires that I serve a waiting week. How does that impact my SUB eligibility?</b></p>
        <p>As of the effective date of the 2003 SUB Plan, if you are on a qualifying
          layoff, you will receive full SUB while serving a state waiting
          week.</p>
      </ul>
      <ul >
        <p><b>Who can I contact for questions regarding my payment?</b></p>
        <p>Questions regarding eligibility and gross amounts should be directed to the
          GM Layoffs and Unemployment Center at 1-800-584-2000, or you may submit a
          “Contact Us” inquiry after logging into the <a routerLink="/mylayoffbenef" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
            My Layoff Benefits</a> option. Questions
          regarding taxes, deductions, wage attachments, etc., should be
          directed to the Hourly Payroll Team at 1-866-245-5957.</p>
      </ul>
    </div>
    <button class="accordion" (click)="toggleAccordion($event)">Transition Support Program (TSP) </button>
    <div class="panel" >
      <ul >
        <p><b>How is TSP calculated?</b></p>
        <p>TSP is another form of payment provided by the SUB Plan.
          You cannot receive TSP until you have exhausted weekly SUB.
          TSP may be paid as a weekly benefit or as one lump sum payment.</p>
        <table style="width: 100%">
          <tr>
            <th colspan="6">Sample Weekly TSP Calculation</th>
          </tr>
          <tr>
            <td>Base Hourly Rate</td>
            <td>Hours Worked</td>
            <td>Total Gross Wages</td>
            <td>50% Total Gross Wages</td>
            <td>Weekly UC Offset</td>
            <td>Gross Weekly TSP Payment</td>
          </tr>
          <tr>
            <td>$30.00</td>
            <td>40</td>
            <td>$1,200.00</td>
            <td>$600.00</td>
            <td>$250.00</td>
            <td>$350.00</td>
          </tr>
        </table>
        <br>
        <table style="width: 100%">
          <tr>
            <th colspan="7">Sample Lump Sum TSP Calculation</th>
          </tr>
          <tr>
            <td>Base Hourly Rate</td>
            <td>Hours Worked</td>
            <td>Total Gross Wages</td>
            <td>50% Total Gross Wages</td>
            <td>52 Weeks Applied</td>
            <td>$10,000 Applied</td>
            <td>Gross Lump Sum TSP Payment</td>
          </tr>
          <tr>
            <td>$30.00</td>
            <td>40</td>
            <td>$1,200.00</td>
            <td>$600.00</td>
            <td>52</td>
            <td>$10,000</td>
            <td>$41,200.00</td>
          </tr>
        </table>
      </ul>
      <ul >
        <p><b> Do I need to apply for TSP if I've submitted a lump sum election?</b></p>
        <p> Yes, you must apply to receive your lump sum.</p>
      </ul>
      <ul >
        <p><b> How can I change my TSP election?</b></p>
        <p> You may send a new TSP election by completing a new
          <a href="assets/Forms/TSP Form.pdf" target="blank"> election form</a> and faxing it to (248) 365-9809
          or upload the document after logging into the <a routerLink="/mylayoffbenef" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
            My Layoff Benefits</a> option.
          You may not change your TSP election if TSP payments have started.
        </p>
      </ul>
      <ul >
        <p><b>Why doesn't my TSP payment
          match the amount communicated in the TSP election package?</b></p>
        <p>The amounts
          contained in the TSP election package are projections only and are
          based upon data in effect at the time the communication was
          produced. Your actual payment will be determined based upon data in
          effect at the time of payment.
        </p>
      </ul>
    </div>
    <button class="accordion" (click)="toggleAccordion($event)">Unemployment Compensation</button>
    <div class="panel" >
      <ul >
        <p><b>Who do I contact with
          questions regarding eligibility for state unemployment compensation
          benefits?</b></p>
        <p>You should
          contact your local unemployment office to determine your
          eligibility.
        </p>
      </ul>
      <ul >
        <p><b>What is a Monetary Determination?</b></p>
        <p> A monetary
          determination is a written notice issued by the state establishing
          your UC eligibility and your UC benefit entitlement. This
          information is given to you when you initially apply for state UC
          benefits. The monetary determination includes your benefits year beginning (BYB) date,
          weekly benefit amount (WBA), Maximum Benefit Amount (MBA) payable
          and the number of benefit weeks allowed. You will need to
          provide this information when applying for a layoff benefit.</p>
      </ul>
      <ul >
        <p><b> I have not received my
          Monetary Determination from the State. Who can I call?</b></p>
        <p> You should contact the state UC office where you applied
          for state UC benefits.</p>
      </ul>
      <ul >
        <p><b>What is my UC benefit year?</b></p>
        <p>The filing of a
          valid UC claim established not only your base period, but also your
          &quot;benefit year&quot;. A benefit year is a one year period
          usually beginning with the start of the first full week for which
          you file your UC claim.
        </p>
      </ul>
    </div>
  </div>
  <div style="padding-bottom: 30px" *ngIf ="globals.gminval !==''">
    <p align="center" style="font-size: 16px"> <strong>
      <a routerLink="/mylayoffbenef/contactUs" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
        Contact Us with Your Questions and Comments</a>
    </strong></p>
  </div>
  <div style="padding-bottom: 30px" *ngIf ="globals.gminval ===''">
    <p align="center" style="font-size: 16px"> <strong>
      <a routerLink="/mylayoffbenef" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
        Contact Us with Your Questions and Comments</a>
    </strong></p>
  </div>
</app-expandable>
