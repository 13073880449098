import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { HomeViewComponent } from './pages/home/home-view/home-view.component';
import { NoaccessComponent } from './pages/noaccess/noaccess.component';

import {PlaceholderComponent} from "./pages/placeholder/placeholder.component";
import {LandingComponent} from "./pages/landing/landing.component";
import {FormsComponent} from "./pages/forms/forms.component";
import {SalaryFormsComponent} from './pages/salary-forms/salary-forms.component';

import {HoursComponent} from './pages/hours/hours.component';
import {IvrInstructionsComponent} from "./pages/ivr-instructions/ivr-instructions.component";
import {FaqComponent} from "./pages/faq/faq.component";


import {MyLayoffBenefitsComponent} from './pages/my-layoff-benefits/my-layoff-benefits.component';
import {MyLayoffBenefitsHomeComponent} from "./pages/my-layoff-benefits/my-layoff-benefits-home/my-layoff-benefits-home.component";

import {PaymentHistoryComponent} from "./pages/processandpaymenthistory/paymenthistory.component";
import {TspElectionComponent} from "./pages/my-layoff-benefits/tsp-election/tsp-election.component";
import {SetupMondetComponent} from './pages/my-layoff-benefits/setup-mondet/setup-mondet.component';

import {SubPlanCountersComponent} from "./pages/my-layoff-benefits/SubPlanCounters/SubPlanCounters.component";
import {ContactUsComponent} from "./pages/my-layoff-benefits/contact-us/contact-us.component";
import {BenefitInitComponent} from "./pages/my-layoff-benefits/benefit-init/benefit-init.component";
import {FileUploadComponent} from './pages/my-layoff-benefits/file-upload/file-upload.component';
import {SalaryFormsAlternateComponent} from "./pages/salary-forms-alternate/salary-forms-alternate.component";
import {SalaryForms225Component} from "./pages/salary-forms-2-25/salary-forms-2-25.component";


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'mylayoffbenef',
    component: MyLayoffBenefitsComponent,
    children: [
      {
        path: '',
        redirectTo: 'cisaCaptcha',
        pathMatch: 'full'
      },
      {
        path: 'cisaCaptcha',
        component: MyLayoffBenefitsHomeComponent
      },
      {
        path: 'cisaMondetRequest',
        component: SetupMondetComponent
      },
      {
        path: 'cisaBenefitInit',
        component: BenefitInitComponent
      },
      {
        path: 'cisaGetHistory',
        component: PaymentHistoryComponent
      },
      {
        path: 'cisaGetCounters',
        component: SubPlanCountersComponent
      },
      {
        path: 'cisaTspElection',
        component: TspElectionComponent
      },
      {
        path: 'fileUpload',
        component: FileUploadComponent
      },
      {
        path: 'contactUs',
        component: ContactUsComponent
      },
    ]
  },
  {
    path: 'gmforms',
    component: FormsComponent
  },
  {
    path: 'stateUC',
    component: SalaryFormsComponent
  },
  {
    path: 'stateUC_11_24',
    component: SalaryFormsAlternateComponent
  },
  {
    path: 'stateUC_02_25',
    component: SalaryForms225Component
  },
  {
    path: 'vru',
    component: IvrInstructionsComponent
  },
  {
    path: 'hours',
    component: HoursComponent
  },
  {
    path: '404',
    component: NoaccessComponent
  },
  // {
  //   path: '**',
  //   redirectTo: '404'
  // }
];

// const homeRoutes: Routes = [
//   {
//     path: '',
//     redirectTo: 'home',
//     pathMatch: 'full'
//   },
//   {
//     path: 'home',
//     component: HomeComponent,
//   },
// ];
//
// const faqRoutes : Routes = [
//
//     {
//       path: 'faq',
//       component: FaqComponent
//     }
//
//
// ];
//
//
// const mylayoffbenefitsRoutes : Routes= [  {
//   path: 'mylayoffbenef',
//   component: MyLayoffBenefitsComponent,
//   children: [
//     {
//       path: '',
//       redirectTo: 'cisaCaptcha',
//       pathMatch: 'full'
//     },
//     {
//       path: 'cisaCaptcha',
//       component: MyLayoffBenefitsHomeComponent
//     },
//     {
//       path: 'cisaMondetRequest',
//       component: SetupMondetComponent
//     },
//     {
//       path: 'cisaBenefitInit',
//       component: BenefitInitComponent
//     },
//     {
//       path: 'cisaGetHistory',
//       component: PaymentHistoryComponent
//     },
//     {
//       path: 'cisaGetCounters',
//       component: SubPlanCountersComponent
//     },
//     {
//       path: 'cisaTspElection',
//       component: TspElectionComponent
//     },
//     {
//       path: 'contactUs',
//       component: ContactUsComponent
//     },
//    ]
// }];
//
// const formsRoutes : Routes = [
//   {
//     path: 'gmforms',
//     component: FormsComponent
//   }
//
//
// ];
//
// const interctiveVoiceResponseRoutes : Routes = [  {
//   path: 'vru',
//   component: IvrInstructionsComponent
// }];
//
// const hoursRoutes = [  {
//   path: 'hours',
//   component: HoursComponent
// }];
//
// const logoffRoutes : Routes = [  {
//   path: '',
//   // component: HomeComponent,
//   redirectTo: 'home',
//   pathMatch: 'full'
// }];
//
//
// const noAccessRoutes: Routes = [
//   {
//     path: 'noAccess',
//     component: NoaccessComponent
//   },
// ];



@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    // RouterModule.forChild(homeRoutes),
    // RouterModule.forChild(noAccessRoutes),
    // RouterModule.forChild(mylayoffbenefitsRoutes),
    // RouterModule.forChild(faqRoutes),
    // RouterModule.forChild(formsRoutes),
    // RouterModule.forChild(interctiveVoiceResponseRoutes),
    // RouterModule.forChild(hoursRoutes),
    // RouterModule.forChild(logoffRoutes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
