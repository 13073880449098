import { Component, OnInit } from '@angular/core';
import {Globals} from '../../../sharedComponents/global-variables/globals';
import {SetupMondetService} from '../../../services/my-layoff-benefits/setup-mondet.service';
import {DatePipe} from '@angular/common';
import {ModalService} from '../../../services/modal/modal.service';
import {FormValidator} from '../../../sharedComponents/formValidation/formValidator';

@Component({
  selector: 'app-setup-mondet',
  templateUrl: './setup-mondet.component.html',
  styleUrls: ['./setup-mondet.component.css']
})
export class SetupMondetComponent implements OnInit {

  constructor(
    public globals: Globals,
    public service: SetupMondetService,
    private modal: ModalService,
    private validator: FormValidator
  ) {
    this.submitValidator = this.validator.getForm(this.validatorSvc, 'submit');
    this.initializePage();
  }

  /**
   * Vars
   */

  searchModel = {
    gmin: ''
  };

  createModel = {
    gmin: '',
    mondetBeginDate: '',
    mondetEndDate: '',
    mondetMbaAmt: '',
    mondetWbaAmt: '',
    mondetWeeks: ''
  };

  mondetData = undefined;

  submitValidator = undefined;
  validatorSvc = 'MondetValidator';

  loadingPage = false;
  systemErrorPage = false;
  siteClosedPage = false;
  incorrectUserPage = false;
  autoSubPage = false;
  mondetCurrentlyExistsPage = false;
  mondetQuestionsPage = false;
  addButtonEnabled = true;
  submitLoading = false;
  submitResult = '';
  addSuccess = false;
  addFailure = false;

  currentState = '';
  mondetYearConverted = '';

  // vars for questions
  benefitYearMessage = '';
  wbaMessage = '';
  mbaMessage = '';
  weeksMessage = '';
  currentYear = '';
  currentMonth = '';
  currentDay = '';
  currentDayConverted = '';

  // vars for question data
  wbaAmountDollars = '';
  wbaAmountCents = '';
  mbaAmountDollars = '';
  mbaAmountCents = '';
  weeksAmountDollars = '';
  weeksAmountCents = '';

  /*
   * note: the value for month is the calendar value, this is intentionally
   * different from JS month values (which are 0 based) to allow for easier
   * calculation in populateDaysDropdown() and createModel dates.
   */
  months = [
    ['01', 'January'],
    ['02', 'February'],
    ['03', 'March'],
    ['04', 'April'],
    ['05', 'May'],
    ['06', 'June'],
    ['07', 'July'],
    ['08', 'August'],
    ['09', 'September'],
    ['10', 'October'],
    ['11', 'November'],
    ['12', 'December']
  ];
  days = [];
  years = [];

  states = new Map<string, string>([
    ['840', 'Alabama'],
    ['841', 'Alaska'],
    ['842', 'Arizona'],
    ['843', 'Arkansas'],
    ['844', 'California'],
    ['845', 'Colorado'],
    ['846', 'Connecticut'],
    ['847', 'Delaware'],
    ['848', 'Florida'],
    ['849', 'Georgia'],
    ['850', 'Hawaii'],
    ['851', 'Idaho'],
    ['852', 'Illinois'],
    ['853', 'Indiana'],
    ['854', 'Iowa'],
    ['855', 'Kansas'],
    ['856', 'Kentucky'],
    ['857', 'Louisiana'],
    ['858', 'Maine'],
    ['859', 'Maryland'],
    ['860', 'Massachusettes'],
    ['861', 'Michigan'],
    ['862', 'Minnesota'],
    ['863', 'Mississippi'],
    ['864', 'Missouri'],
    ['865', 'Montana'],
    ['866', 'Nebraska'],
    ['867', 'Nevada'],
    ['868', 'New Hampshire'],
    ['869', 'New Jersey'],
    ['870', 'New Mexico'],
    ['871', 'New York'],
    ['872', 'North Carolina'],
    ['873', 'North Dakota'],
    ['874', 'Ohio'],
    ['875', 'Rhode Island'],
    ['876', 'South Carolina'],
    ['877', 'South Dakota'],
    ['878', 'Tennesee'],
    ['879', 'Oklahoma'],
    ['880', 'Oregon'],
    ['881', 'Pennsylvania'],
    ['882', 'Texas'],
    ['883', 'Utah'],
    ['884', 'Vermont'],
    ['885', 'Virginia'],
    ['886', 'Washington'],
    ['887', 'West Virginia'],
    ['888', 'Wisconsin'],
    ['889', 'Wyoming']
  ]);

  mondetMessages = new Map<string, string> ([
    ['740', 'benefit year begins or B.Y.B. date'],
    ['741', 'benefit year ends or B.Y.E. date'],
    ['742', 'effective date'],
    ['743', 'date of claim'],
    ['744', 'date filed'],
    ['745', 'benefit period begin date'],
    ['750', 'maximum benefit amount or M.B.A.'],
    ['751', 'maximum award amount'],
    ['752', 'maximum payable amount'],
    ['753', 'available credits amount'],
    ['754', 'maximum benefit balance amount'],
    ['755', 'total amount of benefits'],
    ['756', 'benefit credit amount'],
    ['757', 'amount chargeable'],
    ['758', 'max entitlement amount'],
    ['765', 'weekly benefit amount or W.B.A.'],
    ['766', 'weekly award amount'],
    ['767', 'weekly rate amount'],
    ['768', 'total weekly amount'],
    ['769', 'pay rate amount'],
    ['770', 'weekly benefit rate amount'],
    ['774', 'weeks'],
    ['775', 'potential duration']
  ]);

  /**
   * Methods
   */

  ngOnInit(): void {
  }

  /**
   * Will make a mondet inquire call on page load
   */
  initializePage() {
    this.loadingPage = true;
    this.searchModel.gmin = this.globals.gminval;

    this.service.startMondetM0(this.searchModel).subscribe(response => {
      // console.log(response);
      this.loadingPage = false;
      this.mondetData = response;
      this.addButtonEnabled = true;

      // handle bad response
      if (this.mondetData === null || this.mondetData === undefined ||
          this.mondetData.returnCode === null || this.mondetData.returnCode === '') {
        this.systemErrorPage = true;
        return;
      }

      // handle bad returns
      switch (this.mondetData.returnCode) {
        case '888':
          this.siteClosedPage = true;
          return;
        case '912':
          this.incorrectUserPage = true;
          return;
        case '913':
          this.autoSubPage = true;
          return;
        case '998':
        case '999':
          this.systemErrorPage = true;
          return;
      }

      // if dates are empty, show questions; else show mondet exists page
      if (this.mondetData.mondetBeginDate === '0000-00-00' && this.mondetData.mondetEndDate === '0000-00-00') {
        this.mondetQuestionsPage = true;

        // generate years for dropdown
        const today = new Date();
        this.years.push([(today.getFullYear() - 1).toString(), (today.getFullYear() - 1).toString()]);
        this.years.push([today.getFullYear().toString(), today.getFullYear().toString()]);
        this.years.push([(today.getFullYear() + 1).toString(), (today.getFullYear() + 1).toString()]);
        this.currentYear = (today.getFullYear() - 1).toString();

      }
      else {
        this.mondetCurrentlyExistsPage = true;

        const datepipe: DatePipe = new DatePipe('en-US');

        // set year var, if Begin Date is 0000-00-00 then use End Date, else reverse
        if (this.mondetData.mondetBeginDate === '0000-00-00') {
          this.mondetYearConverted = datepipe.transform(this.mondetData.mondetEndDate, 'MM/dd/yyyy');
        }
        else {
          this.mondetYearConverted = datepipe.transform(this.mondetData.mondetBeginDate, 'MM/dd/yyyy');
        }
      }

      this.currentState = this.states.get(this.mondetData.state3Digit);

      // set the first question based on whether byb or bye was returned
      if (this.mondetData.mondetBybCode !== '000') {
        this.benefitYearMessage = this.mondetMessages.get(this.mondetData.mondetBybCode);
      }
      else {
        this.benefitYearMessage = this.mondetMessages.get(this.mondetData.mondetByeCode);
      }

      // assign verbiage
      if (this.mondetData.mondetWbaCode !== '000') {
        this.wbaMessage = this.mondetMessages.get(this.mondetData.mondetWbaCode);
      }
      if (this.mondetData.mondetMbaCode !== '000') {
        this.mbaMessage = this.mondetMessages.get(this.mondetData.mondetMbaCode);
      }
      if (this.mondetData.mondetWeeksCode !== '000') {
        this.weeksMessage = this.mondetMessages.get(this.mondetData.mondetWeeksCode);
      }


    }, err => {
      this.systemErrorPage = true;
      this.loadingPage = false;
    });
  }// end initializePage()

  /**
   * Used to fill out the days dropdown based on the chosen month/year
   */
  populateDaysDropdown() {
    // only populate when both year AND month are filled
    if (this.currentYear === null || this.currentMonth === null ||
        this.currentYear === ''   || this.currentMonth === '') {
      this.days = [];
      this.currentDay = '';
      return;
    }

    // reset list
    this.days = [];

    // obtain number of days in current month and push to array
    const timeA = new Date(parseInt(this.currentYear, 10), parseInt(this.currentMonth, 10));
    const timeB = new Date(timeA.getTime() - 86400000);
    const daysInMonth = timeB.getDate();
    for (let i = 1; i <= daysInMonth; ++i) {
      this.days.push([i.toString(), i.toString()]);
    }

    // set default date as 1st of the month
    this.currentDay = '1';
  }

  /**
   * Sends fields to backend to attempt inserting mondet record.
   */
  createMondet() {
    this.addButtonEnabled = false;

    if (this.currentYear === null || this.currentYear === '' ||
        this.currentMonth === null || this.currentMonth === '' ||
        this.currentDay === null || this.currentDay === '') {
      alert('Please enter a valid date.');
      this.addButtonEnabled = true;
      return;
    }

    // validate fields
    if (this.mondetData.mondetWbaCode !== '000') {
      if (this.wbaAmountDollars.length === 0 || this.wbaAmountCents.length === 0) {
        alert('Please enter your ' + this.wbaMessage + '.');
        this.addButtonEnabled = true;
        return;
      }
      if (isNaN(Number(this.wbaAmountDollars)) || isNaN(Number(this.wbaAmountCents))) {
        alert('Your ' + this.wbaMessage + ' must be numeric.');
        this.addButtonEnabled = true;
        return;
      }
      if (this.wbaAmountCents.length < 2) {
        alert('You have entered an invalid ' + this.wbaMessage + ' cents, it must be 2 digits.');
        this.addButtonEnabled = true;
        return;
      }
    }

    if (this.mondetData.mondetMbaCode !== '000') {
      if (this.mbaAmountDollars.length === 0 || this.mbaAmountCents.length === 0) {
        alert('Please enter your ' + this.mbaMessage + '.');
        this.addButtonEnabled = true;
        return;
      }
      if (isNaN(Number(this.mbaAmountDollars)) || isNaN(Number(this.mbaAmountCents))) {
        alert('Your ' + this.mbaMessage + ' must be numeric.');
        this.addButtonEnabled = true;
        return;
      }
      if (this.mbaAmountCents.length < 2) {
        alert('You have entered an invalid ' + this.mbaMessage + ' cents, it must be 2 digits.');
        this.addButtonEnabled = true;
        return;
      }
    }

    if (this.mondetData.mondetWeeksCode !== '000') {
      if (this.weeksAmountDollars.length === 0 || this.weeksAmountCents.length === 0) {
        alert('Please enter your ' + this.weeksMessage + '.');
        this.addButtonEnabled = true;
        return;
      }
      if (isNaN(Number(this.weeksAmountDollars)) || isNaN(Number(this.weeksAmountCents))) {
        alert('Your ' + this.weeksMessage + ' must be numeric.');
        this.addButtonEnabled = true;
        return;
      }
      if (this.weeksAmountCents.length > 1) {
        alert('You have entered an invalid ' + this.weeksMessage + ', it must be 1 digit.');
        this.addButtonEnabled = true;
        return;
      }
    }

    // update vars for create
    this.createModel = {
      gmin: this.globals.gminval,
      mondetBeginDate: '',
      mondetEndDate: '',
      mondetMbaAmt: '',
      mondetWbaAmt: '',
      mondetWeeks: ''
    };

    if (this.currentDay.length < 2) {
      this.currentDayConverted = '0' + this.currentDay;
    }
    else {
      this.currentDayConverted = this.currentDay;
    }

    // assign values to createModel
    // if (this.mondetData.mondetBybCode !== '000') {
      this.createModel.mondetBeginDate = this.currentMonth + this.currentDayConverted + this.currentYear;
    // }
    // else {
      this.createModel.mondetEndDate = this.currentMonth + this.currentDayConverted + this.currentYear;
    // }

    if (this.mondetData.mondetWbaCode !== '000') {
      this.createModel.mondetWbaAmt = this.wbaAmountDollars + '.' + this.wbaAmountCents;
    }
    else {
      this.createModel.mondetWbaAmt = '000.00';
    }

    if (this.mondetData.mondetMbaCode !== '000') {
      this.createModel.mondetMbaAmt = this.mbaAmountDollars + '.' + this.mbaAmountCents;
    }
    else {
      this.createModel.mondetMbaAmt = '00000.00';
    }

    if (this.mondetData.mondetWeeksCode !== '000') {
      this.createModel.mondetWeeks = this.weeksAmountDollars + '.' + this.weeksAmountCents;
    }
    else {
      this.createModel.mondetWeeks = '0.0';
    }

    // console.log(this.createModel);

    this.submitLoading = true;

    // make call to create mondet
    this.service.createMondetM0(this.createModel).subscribe(response => {
      // console.log(response);

      this.submitResult = response;

      this.submitLoading = false;
      this.mondetQuestionsPage = false;


      if (this.submitResult === '000') {
        this.addSuccess = true;
      }
      else if (this.submitResult === '999' || this.submitResult === '998') {
        this.systemErrorPage = true;
      }
      else {
        this.addFailure = true;
      }


    }, err => {
      this.submitLoading = false;
      this.mondetQuestionsPage = false;
      this.systemErrorPage = true;
      this.submitLoading = false;
    });
  }

  getprivacyinfo(){
    this.modal.createModal('Privacy',  {
      message: [this.globals.i18n.messages.privacy.text1, this.globals.i18n.messages.privacy.text2, this.globals.i18n.messages.privacy.text3],
      callback: data => {
        if (data.informative){
          return;
        }else{

        }
      }

    });
  }

}
